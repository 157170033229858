
import { prop, Vue } from "vue-class-component";

class Props {
  label = prop<string>({
    default: "",
    type: String
  });
  bold = prop<string>({
    default: "semibold",
    type: String
  });
  fontSize = prop<number>({
    default: 16,
    type: Number
  });
  hint = prop<string>({
    default: "",
    type: String
  });
  required = prop<boolean>({
    default: false,
    type: Boolean
  });
}
export default class Title extends Vue.with(Props) {}
